<template>
  <grid
    item-min-width="300px"
    class="align-center account"
  >
    <v-select
      :items="statAggValues"
      item-value="id"
      item-text="text"
      v-model="computedAgg"
    />
    <between-date
      :document.sync="computedDates"
      low
      :label="$t('t.Date')"
      class="value"
      ref="input"
    />
  </grid>
</template>

<script>
export default {
  components: {
    BetweenDate: () => import('../cond-expr/comparand/between-date'),
    Grid: () => import('@/components/grid')

  },
  computed: {
    computedAgg: {
      get () { return this.dataAgg },
      set (v) {
        this.dataAgg = v
        this.dataDocumentEmit()
      }
    },
    computedDates: {
      get () { return this.dataDates },
      set (v) {
        this.dataDates = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = { type: 'agg', agg: this.dataAgg, from: this.dataDates[0], to: this.dataDates[1] }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('input', this.lodash.cloneDeep(doc))
      }
    }
  },
  data () {
    return {
      dataAgg: null,
      dataDates: [],
      statAggValues: [
        { id: 'avg', text: this.$t('t.Average') },
        { id: 'min-value', text: this.$t('t.MinValue') },
        { id: 'max-value', text: this.$t('t.MaxValue') },
        { id: 'min-date', text: this.$t('t.MinDate') },
        { id: 'max-date', text: this.$t('t.MaxDate') }
      ],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  props: {
    value: Object,
    targetDocTy: String
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        const a = this.lodash.cloneDeep(v)
        this.dataAgg = a?.agg
        this.dataDates = [a?.from, a?.to]
        this.dataDocumentEmit()
      }
    }
  }
}
</script>
